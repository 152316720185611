import React, { useState, useEffect } from 'react';
import $ from "jquery";

function App() {

  const [isSticky, setSticky] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setSticky(scrollTop > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  $('.nav-link').on('click', function () {
    $('.navbar-collapse').removeClass('show');
  });

  const [menuOpen, setMenuOpen] = useState(false);

  const menuOpenClose = () => {
    setMenuOpen(!menuOpen);
  };

  return (
      <>
        <header className={isSticky ? 'scrolled' : ''}>
          <nav className={`navbar container  navbar-expand-md navbar-dark bg-transparent`}>
            <div className="container-fluid">
              <a className="navbar-brand d-md-none" href="#">
                <img src="./img/logo.png" alt="" />
              </a>
              <button onClick={menuOpenClose}
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className={`collapse navbar-collapse ${menuOpen ? 'show' : ''}`} id="navbarNav">
                <ul className="navbar-nav mx-auto">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="#home">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#about">
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#tokenomics">
                      Tokenomics
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#roadmap">Roadmap</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#social">Social</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>

        <main>
          <section id="home" className="king text-center" style={{ backgroundImage: 'url("./img/cats-city.jpg")' }}>
            <div className="overlay">
              <div className="container">

                <div className="bangcat">
                  <blockquote className="electric bubble">
                    <br/>
                    <span>Welcome to the <b>BangCat!</b> <br/> The new ruler of the crypto world.</span>
                  </blockquote>

                  <img className="" src="./img/tokenomics.png" alt=""/>
                </div>
              </div>
            </div>
          </section>

          <section className="partners">
            <div className="container">

              <h3 className="text-white">Partners</h3>

              <ul className="m-0 p-0">
                <li>
                  <img src="./img/partners/pinksale-logo.png" alt=""/>
                </li>
                <li>
                  <img src="./img/partners/dextools-logo.png" alt=""/>
                </li>
                <li>
                  <img src="./img/partners/dexview-logo.png" alt=""/>
                </li>
                <li>
                  <img src="./img/partners/dexscreener-logo.png" alt=""/>
                </li>
                <li>
                  <img src="./img/partners/raydium-logo.png" alt=""/>
                </li>
              </ul>
            </div>
          </section>

          <section id="about" className="about">
            <div className="container text-center">

              <h3 className="text-white mt-4">About Us</h3>
              <p className="text-white">
                BangCat is a decentralized token on the Solana blockchain. Our goal is to create a community-driven token that rewards holders and supporters by the time.
              </p>
              <p className="text-white">
                We are a team of developers and some based chads who are passionate about creating a token that makes a positive impact on the ecosystem.
              </p>
              <p className="text-white">
                All of our team members worked for different projects due time. Bang Cat will be art of our past experiences.
              </p>
              <p className="text-white">
                First Project as team. Bangcat will be our reputation project. Each member will spend all of their effort, with some money.
              </p>
              <p className="text-white">
                We know how to build and use the hype. Are you joining the Big Bang Cat Domination on SOLANA Chain?
              </p>
            </div>

            <div className="image-box">
              <img className="img-fluid w-100" src="./img/about.png" alt=""/>
            </div>
          </section>

          <section id="tokenomics" className="tokenomics">
            <div className="container">
            <div className="row inner-box">
                <div className="col-md-6 col-12 pb-5">
                  <h3 className="text-white mt-4">TOKENOMICS</h3>
                  <div className="tokenomics-info">
                    <ul>
                      <li id="tokenomics-li">
                        <div className="text-white">
                          <img height="30px" className="me-1" src="./img/bullet.svg" alt=""/> NAME:
                          <span className="fw-bold text-white-50"> BangCat</span>
                          <br />
                        </div>
                        <hr />
                        <div className="text-white">
                          <img height="30px" className="me-1" src="./img/bullet.svg" alt=""/> SYMBOL:
                          <span className="fw-bold text-white-50"> BCAT</span>
                          <br />
                        </div>
                        <hr />
                        <div className="text-white">
                          <img height="30px" className="me-1" src="./img/bullet.svg" alt=""/> SUPPLY:
                          <span className="fw-bold text-white-50"> 100.000.000</span>
                          <br />
                        </div>
                        <hr />
                        <div className="text-white">
                          <img height="30px" className="me-1" src="./img/bullet.svg" alt=""/> TEAM TOKENS:
                          <span className="fw-bold text-white-50"> NO</span>
                          <br />
                        </div>
                        <hr />
                        <div className="text-white">
                          <img height="30px" className="me-1" src="./img/bullet.svg" alt=""/> LP TOKEN:
                          <span className="fw-bold text-white-50"> BURN</span>
                          <br />
                        </div>
                        <hr />
                        <div className="text-white">
                          <img height="30px" className="me-1" src="./img/bullet.svg" alt=""/> TAX:
                          <span className="fw-bold text-white-50"> 0%</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-12 token_bg">
                  <img src="./img/tkn.png" alt="" />
                </div>
              </div>
            </div>
          </section>

          <section id="roadmap" className="roadmap">
            <div className="container">
              <h3 className="text-white text-center">ROADMAP</h3>
              <div className="row">
                <div className="col-md-4 col-12">
                  <div className="roadmap-box">
                    <h4>PHASE 1</h4>
                    <ul>
                      <li><img height="30px" className="me-1" src="./img/bullet.svg" alt=""/>  Launch on Pinksale</li>
                      <li><img height="30px" className="me-1" src="./img/bullet.svg" alt=""/>  Launch of token on Raydium</li>
                      <li><img height="30px" className="me-1" src="./img/bullet.svg" alt=""/>  AveDex, DexTools, DexScreener Trending</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="roadmap-box">
                    <h4>PHASE 2</h4>
                    <ul>
                      <li><img height="30px" className="me-1" src="./img/bullet.svg" alt=""/>  CoinGecko and CoinMarketCap Listings</li>
                      <li><img height="30px" className="me-1" src="./img/bullet.svg" alt=""/>  BCAT marketing campaign through Twitter, Telegram</li>
                      <li><img height="30px" className="me-1" src="./img/bullet.svg" alt=""/>  10.000 Holders</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="roadmap-box">
                    <h4>PHASE 3</h4>
                    <ul>
                      <li><img height="30px" className="me-1" src="./img/bullet.svg" alt=""/>  Increasing Holders Amount</li>
                      <li><img height="30px" className="me-1" src="./img/bullet.svg" alt=""/>  Partnerships with Top callers and influencers</li>
                      <li><img height="30px" className="me-1" src="./img/bullet.svg" alt=""/>  Applications for CEX Listings</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <footer id="social">
          <div className="container text-center text-white">
            <h3 className="">Follow Us!</h3>
            <p className="">
              Stay Connected for the latest updates, news, and exciting developments
              in the BangCat.
            </p>
            <div className="socialBox">
              <a href="https://x.com/BangCatSOL">
                <i className="fa-brands fa-x-twitter" />
              </a>
              <a href="https://t.me/BangCatSOL">
                <i className="fa-brands fa-telegram" />
              </a>
            </div>
          </div>
        </footer>
      </>
  );
}

export default App;
